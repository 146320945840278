<template>
  <div>
    <en-table-layout :tableData="pageData.data">
      <template slot="toolbar">
        <div class="col-auto toolbar-title">
          商品专题列表
        </div>
        <div class="col-auto">
          <el-form-item label="">
            <el-input
              style="width: 220px"
              size="medium"
              v-model="params.navigation_name"
              placeholder="请输入专题名称进行搜索"
            />
          </el-form-item>
        </div>
        <div class="col-auto">
          <el-button type="primary" size="small" @click="handleSearch">
            搜索
          </el-button>
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <el-button type="primary" size="small" @click="handleAddSpecial">
            添加专题
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="专题名称" min-width="300">
          <template slot-scope="scope">
            <el-tooltip placement="top" :content="scope.row.navigation_name">
              <div class="text-ellipsis">
                {{ scope.row.navigation_name }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="商品数量" width="100">
          <template slot-scope="scope">{{ scope.row.count || 0 }}</template>
        </el-table-column>

        <el-table-column label="更新时间" width="160">
          <template slot-scope="scope">
            {{ (scope.row.update_date || scope.row.create_date) | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_date | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="() => handleEdit(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="() => handleEditPage(scope.$index, scope.row)">
              编辑页面
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="() => handleDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPageIndex"
        :page-size="params.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>

    <x-dialog :proxy="editDialog">
      <el-form :model="siteMenuForm" :rules="siteMenuRules" ref="siteMenuForm" label-width="110px">
        <el-form-item label="商品专题名称" prop="navigation_name">
          <el-input placeholder="请输入商品专题名称" v-model="siteMenuForm.navigation_name" clearable :maxlength="20"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_PageSpecial from '@/api/pageSpecial';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  components: {
    XDialog,
    EnTableLayout
  },
  data() {
    let validateNavigation = (rule, value, callback) => {
      let validate = /^\S.*$/gi.test(value)
      if (value===undefined||value==='') callback(new Error('请输入商品专题名称'))
      else if (!validate) callback(new Error('商品专题名称不能以空格开始'))
      else if (value.split('/').length>1) callback(new Error('商品专题名称不能包含 /'))
      else callback()
    };
    return {
      currentPageIndex: 1,
      pageData: {data: [], data_total: 0},
      params: {
        page_no: 1,
        page_size: 20,
      },
      /**
       * 表单
       */
      siteMenuForm: {
        navigation_id: 0,
        navigation_name: '',
        page_data: '',
        sort: 0,
        count: 0,
        image: '',
      },
      siteMenuRules: {
        navigation_name: [{validator: validateNavigation, trigger: 'blur'}],
      },
      editDialog: $xDialog.create({
        width: '500px',
        beforeConfirm: () => this.submitSiteMenuForm('siteMenuForm'),
      }),
    };
  },
  watch: {
    $route: function () {
      this.GET_SpecialList();
    }
  },
  created() {
    this.GET_SpecialList();
  },
  methods: {
    handleSearch() {
      this.params.page_no = 1;
      this.GET_SpecialList();
    },
    GET_SpecialList() {
      let params = this.params;
      API_PageSpecial.getSpecialList(params).then(res => {
        this.pageData = res;
      });
    },
    handleSizeChange(val) {
      this.params.page_size = val;
      this.GET_SpecialList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.params.page_no = val;
      this.GET_SpecialList();
      console.log(`当前页: ${val}`);
    },
    handleDelete(index, row) {
      this.$confirm(`确定要删除该商品专题吗？删除后无法还原该商品专题。`, '提示', {type: 'warning'})
        .then(() => {
          API_PageSpecial.delSpecial(row.navigation_id).then(res => {
            this.GET_SpecialList();
          });
        })
        .catch(() => {
        });
    },
    handleAddSpecial() {
      this.siteMenuForm = {};
      this.editDialog.display({
        title: '添加专题'
      });
    },
    handleEditPage(index, row) {
      this.$router.push({
        name: 'floorWidthParams',
        params: {
          type: 'subject',
          id: row.navigation_id
        }
      });
    },
    handleEdit(index, row) {
      this.siteMenuForm = this.MixinClone(row);
      this.editDialog.display({
        title: '编辑专题'
      });
    },
    /** 添加、编辑子级楼层 提交表单 */
    submitSiteMenuForm(formName) {
      return new Promise(resolve => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const {navigation_id} = this.siteMenuForm;

            if (navigation_id) {
              API_PageSpecial.editSpecial(this.siteMenuForm).then(response => {
                this.editDialog.dismiss();
                this.$message.success('保存成功！')
                // this.MixinSetTableData(this.tableData, 'navigation_id', navigation_id, response);
                this.GET_SpecialList();
                resolve(true);
              }, err => resolve(false));
            } else {
              API_PageSpecial.addSpecial(this.siteMenuForm).then(response => {
                this.editDialog.dismiss();
                this.$message.success('添加成功！')
                this.GET_SpecialList();
                resolve(true);
              }, err => resolve(false));
            }
          } else {
            this.$message.error('表单填写有误，请检查！')
            resolve(false)
          }
        });
      })
    },
  }
};
</script>

<style lang="scss">
</style>
